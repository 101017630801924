<template>
  <v-dialog
    v-model="dialog"
    class="dialog font-weight-medium"
    max-width="1050"
    persistent
  >
    <v-card class="card text-center py-10">
      <v-row class="d-flex align-center justify-space-between pb-7">
        <span />
        <h3 class="card-text">
          {{ $t("create_campaign_page.choose_plan_step_title") }}
        </h3>
        <v-btn icon @click="$emit('handleCancel')">
          <v-icon color="black">mdi-close</v-icon>
        </v-btn>
      </v-row>
      <div class="justify-center d-flex">
        <span
          :class="
            planTypeChose === 'monthlyFee'
              ? 'color-blue font-weight-bold'
              : 'cursor-pointer'
          "
          @click="planTypeChose = 'monthlyFee'"
          >{{ $t("create_campaign_page.plan_type_monthly_text") }}</span
        >
        <v-divider vertical class="mx-5" style="height: 25px" />
        <div>
          <span
            :class="
              planTypeChose === 'annualFee'
                ? 'color-blue font-weight-bold'
                : 'cursor-pointer'
            "
            @click="planTypeChose = 'annualFee'"
            >{{ $t("create_campaign_page.plan_type_annual_text") }}</span
          >
          <p class="mt-1">
            <span class="discount-badge">SAVE 15%</span>
          </p>
        </div>
      </div>
      <v-row class="px-5 mt-2">
        <v-col
          md="4"
          v-for="{ id, name, annualFee, monthlyFee } of allPlans"
          :key="id"
        >
          <v-card class="plans-card py-7 px-4">
            <h3 style="font-weight: 500">{{ name }}</h3>
            <h2>
              ${{ planTypeChose === "annualFee" ? annualFee / 12 : monthlyFee }}
            </h2>
            <p v-if="planTypeChose === 'annualFee'">
              <span class="discount-badge">SAVE 15%</span>
            </p>
            <v-divider />
            <p class="font-family-raleway-medium mt-6">
              Billed
              {{
                planTypeChose === "annualFee"
                  ? $t("create_campaign_page.plan_type_annual_text")
                  : $t("create_campaign_page.plan_type_monthly_text")
              }}
            </p>
            <div class="font-family-raleway-light my-4" v-if="!isHaveUserActivePlan">
              <a
                class="text-decoration-underline font-family-raleway-medium"
                @click="clickShowPromocodeInput(id)"
                >{{ $t("get_discount") }}</a
              >
              <div class="mt-3">
                <div
                  class="d-flex align-center"
                  v-if="isAddPromocode && selectedPlanIdForPromo === id"
                >
                  <v-text-field
                    v-model="couponId"
                    :placeholder="$t('enter_promocode')"
                    dense
                    outlined
                    height="30"
                    hide-details
                    class="border-16 font-family-raleway-medium"
                    :error="isInvalidCoupon"
                    :success="isValidCoupon"
                    @input="changeCouponId"
                  />
                  <v-btn
                    class="text-none button font-family-raleway-medium"
                    style="border-radius: 10px"
                    @click="checkCoupon"
                    :loading="isCheckingPromo"
                    :disabled="!couponId"
                  >
                    {{ $t("apply_btn") }}
                  </v-btn>
                </div>
                <div
                  class="text-left"
                  v-if="isAddPromocode && selectedPlanIdForPromo === id"
                >
                  <span
                    class="color-red font-family-raleway-medium ml-3"
                    style="font-size: 13px; color: #27ae60"
                    v-if="isInvalidCoupon && selectedPlanIdForPromo === id"
                    >{{ $t("create_campaign_page.coupon_invalid_text") }}</span
                  >
                  <span
                    class="font-family-raleway-medium ml-3"
                    style="font-size: 13px; color: #27ae60"
                    v-if="isValidCoupon && selectedPlanIdForPromo === id"
                    >{{ $t("create_campaign_page.coupon_valid_text") }}</span
                  >
                </div>
              </div>
            </div>
            <p
              class="font-family-raleway-medium"
              style="font-size: 14px"
              v-if="isValidCoupon"
            >
              {{ $t("promo_discount") }}:
              <span class="font-family-raleway-bold">
                - ${{ ((annualFee / 12) * couponDiscount) / 100 }}</span
              >
            </p>
            <v-btn
              @click="choosePlan(id)"
              class="button text-none"
              width="100%"
              height="48"
              :disabled="
                (chosenPlanId === id &&
                  planTypeChose ===
                    (planType ===
                    $t('create_campaign_page.plan_type_annual_text')
                      ? 'annualFee'
                      : 'monthlyFee')) ||
                (isInvalidCoupon && selectedPlanIdForPromo === id) ||
                (isAddPromocode &&
                  selectedPlanIdForPromo === id &&
                  isInvalidCoupon)
              "
              :loading="loading && choosingPlanId === id"
              >{{
                chosenPlanId === id &&
                planTypeChose ===
                  (planType === $t("create_campaign_page.plan_type_annual_text")
                    ? "annualFee"
                    : "monthlyFee")
                  ? $t("plan_chosen")
                  : $t("create_campaign_page.choose_plan_button")
              }}
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
      <v-btn
        style="background-color: white; box-shadow: none"
        class="font-family-raleway-medium mt-10 text-decoration-underline"
        @click="$emit('handleCancel')"
        >{{ $t("cancel") }}
      </v-btn>
    </v-card>
  </v-dialog>
</template>
<script>
import { plansService } from "@/services/plans";
import { authService } from "@/services/auth";

export default {
  name: "ChangePlan",
  props: {
    dialog: Boolean,
    chosenPlanId: String,
    planType: String,
    isHaveUserActivePlan: Boolean,
    paymentMethodId: String,
  },
  data() {
    return {
      planTypeChose: "annualFee",
      allPlans: [],
      loading: false,
      isAddPromocode: false,
      couponId: "",
      couponDiscount: 0,
      selectedPlanIdForPromo: "",
      choosingPlanId: "",
      isInvalidCoupon: false,
      isValidCoupon: false,
      isCheckingPromo: false,
    };
  },
  async created() {
    const response = await plansService.getAllPlans();
    const { data } = response;
    this.allPlans = data;
    this.planTypeChose =
      this.planType === this.$t("create_campaign_page.plan_type_annual_text")
        ? "annualFee"
        : "monthlyFee";
  },
  methods: {
    changeCouponId() {
      this.isInvalidCoupon = false;
      this.isValidCoupon = false;
    },
    async checkCoupon() {
      this.isCheckingPromo = true;
      try {
        const { data } = await authService.checkCoupon(this.couponId);
        this.couponDiscount = data?.discountPercentage;
        this.isInvalidCoupon = false;
        this.isValidCoupon = true;
      } catch (err) {
        this.isInvalidCoupon = true;
        this.loading = false;
        this.isValidCoupon = false;
      }
      this.isCheckingPromo = false;
    },
    clickShowPromocodeInput(id) {
      if (this.selectedPlanIdForPromo !== id) {
        this.isAddPromocode = true;
      } else {
        this.isAddPromocode = !this.isAddPromocode;
        if (!this.isAddPromocode) {
          this.couponId = "";
          this.isInvalidCoupon = false;
          this.isValidCoupon = false;
        }
      }
      this.selectedPlanIdForPromo = id;
    },
    async choosePlan(planId) {
      this.choosingPlanId = planId;
      this.loading = true;
      const isAnnual = this.planTypeChose === "annualFee";
      if (!this.isHaveUserActivePlan) {
        let payload = {
          paymentMethodId: this.paymentMethodId,
          productId: planId,
          annualStatus: isAnnual,
          couponId: this.couponId,
        }
        if (this.couponId) {
          const data = await authService.createSubscriptionWithCoupon(payload);
          if (data.status === "requires_action") {
            const confirmResult = await this.stripe.confirmCardPayment(
              data.data.clientSecret
            );
            if (confirmResult.error) {
              //ERROR
              console.log(
                "SUCCESSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS NOOOOOOOOOOOOOOOOOO"
              );
            } else {
              //SUCCESS
              this.$notify({
                group: "foo",
                type: "success",
                title: "Plan was chosen successfully!",
              });
              this.$emit("handleUpdateDetails");
              this.$emit("handleCancel");
            }
          } else {
            this.$notify({
              group: "foo",
              type: "success",
              title: "Plan was chosen successfully!",
            });
            this.$emit("handleUpdateDetails");
            this.$emit("handleCancel");
          }
        } else {
          const data = await authService.createSubscription({
            paymentMethodId: this.paymentMethodId,
            productId: planId,
            annualStatus: isAnnual,
          });
          if (data.status === "requires_action") {
            const confirmResult = await this.stripe.confirmCardPayment(
              data.data.clientSecret
            );
            if (confirmResult.error) {
              //ERROR
              console.log(
                "SUCCESSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS NOOOOOOOOOOOOOOOOOO"
              );
            } else {
              //SUCCESS
              this.$notify({
                group: "foo",
                type: "success",
                title: "Plan was chosen successfully!",
              });
              this.$emit("handleUpdateDetails");
              this.$emit("handleCancel");
            }
          } else {
            this.$notify({
              group: "foo",
              type: "success",
              title: "Plan was chosen successfully!",
            });
            this.$emit("handleUpdateDetails");
            this.$emit("handleCancel");
          }
        }
      } else {
        try {
          const data = await authService.changeSubscription(planId, isAnnual);
          if (data.status === "requires_action") {
            var confirmResult2 = await this.stripe.confirmCardPayment(
              data.data.clientSecret
            );
            if (confirmResult2.error) {
              //ERROR
              console.log(
                "SUCCESSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS NOOOOOOOOOOOOOOOOOO"
              );
            } else {
              //SUCCESS
              this.$notify({
                group: "foo",
                type: "success",
                title: "Plan was successfully changed!",
              });
              this.$emit("handleUpdateDetails");
              this.$emit("handleCancel");
            }
          } else {
            this.$notify({
              group: "foo",
              type: "success",
              title: "Plan was successfully changed!",
            });
            this.$emit("handleUpdateDetails");
            this.$emit("handleCancel");
          }
        } catch (err) {
          const errCode = err.response?.statusText;
          const { data } = err.response;
          if (data) {
            this.$notify({
              group: "foo",
              type: "error",
              title: data,
            });
          } else {
            this.$notify({
              group: "foo",
              type: "error",
              title: errCode ? errCode : this.$t("something_wrong"),
            });
          }
        }
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.chosen-plan {
  opacity: 0.8;
}
</style>
